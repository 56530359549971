import React from "react";

// Third party package
import { GoLaw } from "react-icons/go";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";

const CategoriesPromo = () => {
    const categories = [
        { id: 1, name: "Criminal Law" },
        { id: 2, name: "Family Law" },
        { id: 3, name: "Personal Injury Law" },
        { id: 4, name: "Commercial Law" },
    ];
    return (
        <div>
            <div className="w-full">
                <div className="md:w-[700px] lg:w-[1200px] mx-auto py-12 md:py-20 lg:py-28 px-6 lg:px-0">
                    <h1 className="text-[27px] lg:text-[32px] font-Poppins font-semibold  w-full mb-6 md:mb-12 lg:mb-12">
                        Get Work Done In Different Categories
                    </h1>

                    <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                        {categories.map(category => (
                            <VarientAnimation direction="bottom" delay={0.3}>
                                <div key={category.id} className="flex justify-start items-center">
                                    <GoLaw className="text-pink-500 text-2xl mr-4" />
                                    <p className="text-base font-Poppins">{category.name}</p>
                                </div>
                            </VarientAnimation>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoriesPromo;
