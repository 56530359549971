import React from "react";
import AgentProfile from "../../components/pages/Agent/AgentProfile/AgentProfile";

// Components

const Agent = () => {
  return (
    <>
      {/* <AgentPage /> */}
      <AgentProfile />
    </>
  );
};
export default Agent;
