// Configuration
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import agentPrivateRouteRenderer from "./Agent/agentPrivateRouteRenderer";
import agentPublicRouteRenderer from "./Agent/agentPublicRouteRenderer";
import chatPrivateRouteRenderer from "./Agent/ChatPrivateRoutes/chatPrivateRouteRenderer";

// Components

const MasterRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Agent private */}
        {agentPrivateRouteRenderer()}
        {/* agent public */}
        {agentPublicRouteRenderer()}
        {/* Chat Private route */}
        {chatPrivateRouteRenderer()}
      </Routes>
    </BrowserRouter>
  );
};

export default MasterRoute;
