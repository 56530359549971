import React, { useEffect } from "react";

// Components
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import SingleServicesContainer from "../../shared/Components/LawyerAgentKaziService/SingleServices/SingleServicesContainer";
import Footer from "../../shared/Footer/Footer";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import { BottomNav } from "../../../Wrapper/HomePage/mobileversion/BottomNav";

const DynamicSingleService = () => {
  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="hidden lg:block">
        <CustomHeader title={"Service Details"} />
        <div className="mt-8">
          <SingleServicesContainer {...{ dynamic: true }} />
        </div>
        <Footer />
      </div>

      {/* Mobile responsive */}
      <div className="lg:hidden">
        <MobileBackButton name={"Service Details"} />
        <div className="px-6 mt-4">
          <SingleServicesContainer {...{ dynamic: true, responsive: true }} />
        </div>
        <BottomNav />
      </div>
    </div>
  );
};

export default DynamicSingleService;
