import { Route } from "react-router";
import ChatPrivateRoutes from "./ChatPrivateRoutes";
import RequireAuth from "../../../components/shared/RequireAuth/RequireAuth";

const chatPrivateRouteRenderer = () => {
  return ChatPrivateRoutes.map((route) => {
    return (
      <Route
        key={route.id}
        path={route.path}
        element={<RequireAuth>{route.element}</RequireAuth>}
      >
        {route.nestedRoutes?.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={<RequireAuth>{route.element}</RequireAuth>}
            >
              {route.nestedRoutes?.map((route) => {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={<RequireAuth>{route.element}</RequireAuth>}
                  >
                    {route.nestedRoutes?.map((route) => {
                      return (
                        <Route
                          key={route.id}
                          path={route.path}
                          element={<RequireAuth>{route.element}</RequireAuth>}
                        />
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Route>
    );
  });
};

export default chatPrivateRouteRenderer;
