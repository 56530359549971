import AgentActivity from "../../components/pages/Agent/AgentActivity/AgentActivity";
import AgentProfileContainer from "../../components/pages/Agent/AgentProfile/AgentProfileContainer/AgentProfileContainer";
import AgentReviewsContainer from "../../components/pages/Agent/AgentReview/AgentReviewsContainer";
import AgentServiceForm from "../../components/pages/Agent/AgentServiceForm/AgentServiceForm";
import AgentServicesPage from "../../components/pages/Agent/AgentServicesPage/AgentServicesPage";
import FindAgentPage from "../../components/pages/Agent/FindAgent/FindAgentPage";
import { FindRecentAgent } from "../../components/pages/Agent/FindAgent/FindRecentAgent";
import AllServicesPage from "../../components/pages/AllServicesPage/AllServicesPage";
import { DynamicProfilePage } from "../../components/pages/DynamicProfilePage/DynamicProfilePage";
import { DynamicSingleServiceContainer } from "../../components/pages/DynamicProfilePage/DynamicSingleServiceContainer/DynamicSingleServiceContainer";
import { MobileDynamicProfile } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfile";
import { MobileDynamicEducationalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicEducationalDetails";
import MobileDynamicFamilyDetails from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicFamilyDetails";
import { MobileDynamicOtherDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicOtherDetails";
import { MobileDynamicPersonalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicPersonalDetails";
import { MobileDynamicPhysicalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicPhysicalDetails";
import { MobileDynamicProfessionalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfessionalDetails";
import MobileDynamicProfileActivity from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfileActivity";
import MobileDynamicProfileConnections from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfileConnections";
import MobileDynamicSiblingsDetails from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicSiblingsDetails";
import MobileDynamicServicesContainer from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicServicesContainer/MobileDynamicServicesContainer";
import DynamicSingleService from "../../components/pages/DynamicSingleService/DynamicSingleService";
import FindAlawyerPage from "../../components/pages/FindAlawyer/FindAlawyerPage";
import { FindRecentLawyer } from "../../components/pages/FindAlawyer/FindRecentLawyer";
import FindKaziPage from "../../components/pages/Kazi/FindKazi/FindKaziPage";
import { FindRecentKazi } from "../../components/pages/Kazi/FindKazi/FindRecentKazi";
import Packages from "../../components/pages/packages/Packages";
import { ServiceEdit } from "../../components/shared/Components/LawyerAgentKaziService/ServiceEdit/ServiceEdit";
import SingleServicesContainer from "../../components/shared/Components/LawyerAgentKaziService/SingleServices/SingleServicesContainer";
import { PaymentSuccess } from "../../components/shared/PaymentSuccess/PaymentSuccess";
import EducationalCertificateWeb from "../../components/SingleProfilesUser/CertificateSection/EducationalCertificateWeb";
import PersonalCertificateWeb from "../../components/SingleProfilesUser/CertificateSection/PersonalCertificateWeb";
import EditEducationalInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditEducationalInfo";
import EditOthersInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditOthersInfo";
import EditPersonalInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditPersonalInfo";
import EditPhysicalInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditPhysicalInfo";
import EditProfesionalInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditProfesionalInfo";
import ProfileDetails from "../../components/SingleProfilesUser/profileSection/ProfileDetails";
import Agent from "../../Wrapper/Agent/Agent";
import MobileAgentActivity from "../../Wrapper/HomePage/mobileversion/MobileAgent/MobileAgentActivity/MobileAgentActivity";
import MobileAgentAddServiceForm from "../../Wrapper/HomePage/mobileversion/MobileAgent/MobileAgentAddServiceForm/MobileAgentAddServiceForm";
import MobileAgentServicesPage from "../../Wrapper/HomePage/mobileversion/MobileAgent/MobileAgentServicesPage/MobileAgentServicesPage";
import MobileDynamicSingleServiceContainer from "../../Wrapper/HomePage/mobileversion/MobileDynamicSingleServiceContainer/MobileDynamicSingleServiceContainer";
import SuggestedAgentForMobile from "../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedAgentForMobile/SuggestedAgentForMobile";
import SuggestedKaziForMobile from "../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedKaziForMobile/SuggestedKaziForMobile";
import SuggestedLawyerForMobile from "../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedLawyerForMobile/SuggestedLawyerForMobile";
import MobileLawyerAgentKaziReviewsContainer from "../../Wrapper/HomePage/mobileversion/MobileLawyerAgentKaziReviewsContainer/MobileLawyerAgentKaziReviewsContainer";
import MobileProfServiceEdit from "../../Wrapper/HomePage/mobileversion/MobileProfServiceEdit/MobileProfServiceEdit";
import MobileProfSingleServicesContainer from "../../Wrapper/HomePage/mobileversion/MobileProfSingleServicesContainer/MobileProfSingleServicesContainer";
import { GalleryContainerForMobile } from "../../Wrapper/HomePage/mobileversion/PhotoGallery/GalleryContainerForMobile";
import { Setting } from "../../Wrapper/HomePage/mobileversion/setting/Setting";
import MobileSingleProfilesUser from "../../components/SingleProfilesUser/MobileSingleProfilesUser/MobileSingleProfilesUser";
import { PersonalDetailsMobile } from "../../components/SingleProfilesUser/MobileSingleProfilesUser/PersonalDetailsMobile";
import { MobilePersonalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobilePersonalDetails";
import { MobileProfessionalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileProfessionalDetails";
import { MobileEducationalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileEducationalDetails";
import { MobilePhysicalDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobilePhysicalDetails";
import { MobileOthersDetails } from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileOthersDetails";
import MobileFamilyDetails from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileFamilyDetails";
import MobileSiblingsDetails from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileSiblingsDetails";
import EditFamilyInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditFamilyInfo";
import EditSiblingsInfo from "../../components/SingleProfilesUser/profileSection/EditprofileSection/EditSiblingsInfo";
import MobileProfessionalInfoEdit from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileEditProfile/MobileProfessionalInfoEdit";
import MobileUserConnections from "../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileUserConnections/MobileUserConnections";
import { CertificateMov } from "../../Wrapper/HomePage/mobileversion/CertificateForMobile/CertificateMov";
import { Like } from "../../Wrapper/HomePage/mobileversion/Like";
import { NotificationForMobile } from "../../components/SingleProfilesUser/Notification/NotificationForMobile";
import { DesktopNotification } from "../../components/shared/Notification/DesktopNotification";

const AgentPrivateRoutes = [
  {
    id: 1,
    path: "/agentProfile",
    element: <Agent />,
    nestedRoutes: [
      { id: 1, path: "agentService", element: <AgentServicesPage /> },
      { id: 2, path: "serviceCreate", element: <AgentServiceForm /> },
      {
        id: 3,
        path: "agentSingleServiceDetails/:id",
        element: <SingleServicesContainer />,
      },
      {
        id: 4,
        path: "agentReview",
        element: <AgentReviewsContainer />,
      },
      {
        id: 5,
        path: "agentActivity",
        element: <AgentActivity />,
      },
      {
        id: 6,
        path: "profile",
        element: <AgentProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ProfileDetails />,
          },
          {
            id: 2,
            path: "edit/personalinfo/:id",
            element: <EditPersonalInfo />,
          },
          {
            id: 3,
            path: "edit/profesionalinfo/:id",
            element: <EditProfesionalInfo />,
          },
          {
            id: 4,
            path: "edit/educationalinfo/:id",
            element: <EditEducationalInfo />,
          },
          {
            id: 5,
            path: "edit/physicalinfo/:id",
            element: <EditPhysicalInfo />,
          },
          {
            id: 6,
            path: "edit/othersinfo/:id",
            element: <EditOthersInfo />,
          },
        ],
      },
      {
        id: 7,
        path: "serviceEdit/:id",
        element: <ServiceEdit />,
      },
    ],
  },
  {
    id: 2,
    path: "/mobileAgentServices",
    element: <MobileAgentServicesPage />,
  },
  {
    id: 3,
    path: "/agentAddServiceFormMov",
    element: <MobileAgentAddServiceForm />,
  },
  {
    id: 4,
    path: "agentSingleServiceDetailsMov/:id",
    element: <MobileProfSingleServicesContainer />,
  },
  {
    id: 5,
    path: "/agentActivityMov",
    element: <MobileAgentActivity />,
  },
  {
    id: 6,
    path: "/agentReviewsMov",
    element: <MobileLawyerAgentKaziReviewsContainer />,
  },
  {
    id: 7,
    path: "serviceEditMov/:id",
    element: <MobileProfServiceEdit />,
  },
  {
    id: 8,
    path: "/singleService/:id",
    element: <DynamicSingleService />,
  },
  {
    id: 9,
    path: "/allServices",
    element: <AllServicesPage />,
  },
  {
    id: 10,
    path: "/packages",
    element: <Packages />,
  },
  {
    id: 11,
    path: "/findLawyers",
    element: <FindAlawyerPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentLawyer />,
      },
    ],
  },
  {
    id: 12,
    path: "/profile/:id",
    element: <DynamicProfilePage />,
  },
  {
    id: 13,
    path: "/service/:id",
    element: <DynamicSingleServiceContainer />,
  },
  {
    id: 14,
    path: "/kazi",
    element: <FindKaziPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentKazi role="kazi" />,
      },
    ],
  },
  {
    id: 15,
    path: "agent",
    element: <FindAgentPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentAgent />,
      },
    ],
  },
  {
    id: 16,
    path: "/personalCertificate",
    element: <PersonalCertificateWeb />,
  },
  {
    id: 17,
    path: "/professionalCertificate",
    element: <EducationalCertificateWeb title="prof" />,
  },
  {
    id: 18,
    path: "/educationalCertificate",
    element: <EducationalCertificateWeb title="edu" />,
  },
  {
    id: 19,
    path: "/marriageCertificate",
    element: <EducationalCertificateWeb title="marriage" />,
  },
  {
    id: 20,
    path: "/payment_Success",
    element: <PaymentSuccess />,
  },
  {
    id: 21,
    path: "/setting",
    element: <Setting />,
  },
  {
    id: 22,
    path: "/findLawyerMov",
    element: <SuggestedLawyerForMobile />,
  },
  {
    id: 23,
    path: "/findKaziMov",
    element: <SuggestedKaziForMobile role="kazi" />,
  },
  {
    id: 24,
    path: "/findAgentMov",
    element: <SuggestedAgentForMobile />,
  },
  {
    id: 25,
    path: "/dynamicConnectionsMov/:id",
    element: <MobileDynamicProfileConnections />,
  },
  {
    id: 26,
    path: "/dynamicActivityMov/:id",
    element: <MobileDynamicProfileActivity />,
  },
  {
    id: 27,
    path: "/dynamicGalleryMov/:id",
    element: <GalleryContainerForMobile />,
  },
  {
    id: 28,
    path: "/mobileprofile/:id",
    element: <MobileDynamicProfile />,
  },
  {
    id: 29,
    path: "/dynamicServicesMov/:id",
    element: <MobileDynamicServicesContainer />,
  },
  {
    id: 30,
    path: "/serviceMov/:id",
    element: <MobileDynamicSingleServiceContainer />,
  },
  {
    id: 31,
    path: "/personalProfileDynamic/:id",
    element: <MobileDynamicPersonalDetails />,
  },
  {
    id: 32,
    path: "/professionalProfileDynamic/:id",
    element: <MobileDynamicProfessionalDetails />,
  },
  {
    id: 33,
    path: "/educationalProfileDynamic/:id",
    element: <MobileDynamicEducationalDetails />,
  },
  {
    id: 34,
    path: "/physicalProfileDynamic/:id",
    element: <MobileDynamicPhysicalDetails />,
  },
  {
    id: 35,
    path: "/familyProfileDynamic/:id",
    element: <MobileDynamicFamilyDetails />,
  },
  {
    id: 36,
    path: "/siblingsProfileDynamic/:id",
    element: <MobileDynamicSiblingsDetails />,
  },
  {
    id: 37,
    path: "/otherProfileDynamic/:id",
    element: <MobileDynamicOtherDetails />,
  },
  {
    id: 39,
    path: "/mobileuserprofile",
    element: <MobileSingleProfilesUser />,
    nestedRoutes: [
      {
        id: 1,
        path: "personaldetailsmov",
        element: <PersonalDetailsMobile />,
      },
    ],
  },
  {
    id: 40,
    path: "/personalprofile/:id",
    element: <MobilePersonalDetails />,
  },
  {
    id: 41,
    path: "/professionalprofile/:id",
    element: <MobileProfessionalDetails />,
  },
  {
    id: 42,
    path: "/educationalprofile/:id",
    element: <MobileEducationalDetails />,
  },
  {
    id: 43,
    path: "/physicalprofile/:id",
    element: <MobilePhysicalDetails />,
  },
  {
    id: 44,
    path: "/otherprofile/:id",
    element: <MobileOthersDetails />,
  },
  {
    id: 45,
    path: "/familyprofile/:id",
    element: <MobileFamilyDetails />,
  },
  {
    id: 46,
    path: "/siblingsprofile/:id",
    element: <MobileSiblingsDetails />,
  },
  {
    id: 47,
    path: "/familyInfoEdit/:id",
    element: <EditFamilyInfo />,
  },
  {
    id: 48,
    path: "/siblingsInfoEdit/:id",
    element: <EditSiblingsInfo />,
  },
  {
    id: 49,
    path: "/othersInfoEdit/:id",
    element: <EditOthersInfo />,
  },
  {
    id: 50,
    path: "/physicalInfoEdit/:id",
    element: <EditPhysicalInfo />,
  },
  {
    id: 51,
    path: "/educationalInfoEdit/:id",
    element: <EditEducationalInfo />,
  },
  {
    id: 52,
    path: "/professionalInfoEdit/:id",
    element: <MobileProfessionalInfoEdit />,
  },
  {
    id: 53,
    path: "/personalInfoEdit/:id",
    element: <EditPersonalInfo />,
  },
  {
    id: 54,
    path: "/galleryMov/:id",
    element: <GalleryContainerForMobile />,
  },
  {
    id: 55,
    path: "/connections/:id",
    element: <MobileUserConnections />,
  },
  {
    id: 56,
    path: "/certificatemov",
    element: <CertificateMov />,
  },
  {
    id: 57,
    path: "/like",
    element: <Like />,
  },
  {
    id: 58,
    path: "/desktopNotification/:id",
    element: <DesktopNotification />,
  },
  {
    id: 59,
    path: "/notifications",
    element: <NotificationForMobile />,
  },
];

export default AgentPrivateRoutes;
