import React from "react";

// Components
import createAccountImg from "../../../../assets/images/LandingPageAssets/needSomethingIcon/createAccount.svg";
import postServiceImg from "../../../../assets/images/LandingPageAssets/needSomethingIcon/postService.svg";
import safePaymentImg from "../../../../assets/images/LandingPageAssets/needSomethingIcon/safePayment.svg";
import supportImg from "../../../../assets/images/LandingPageAssets/needSomethingIcon/support.svg";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";

const NeedSomethingDone = () => {
    const infosArray = [
        {
            id: 1,
            heading: "Create Account",
            description: "It's free and easy to create an account. Simply fill in the registration form within couple of minutes.",
            icon: createAccountImg,
        },
        {
            id: 2,
            heading: "Post Service",
            description:
                "It's free and easy to post a service. Simply fill in our simple service form with some attracting images and some simple information.",
            icon: postServiceImg,
        },
        {
            id: 3,
            heading: "Safe Payment",
            description:
                "Only pay for work when it has been completed and you're 100% satisfied with the quality using our milestone payment system.",
            icon: safePaymentImg,
        },
        {
            id: 4,
            heading: "Support",
            description:
                "Our talented team of recruiters can help you find the best freelancer for the job and our technical co-pilots can even manage the project for you",
            icon: supportImg,
        },
    ];

    const indexPreparedForLawyer = index => {
        if (index % 2 === 0) {
            return parseFloat(index / 10);
        } else {
            return parseFloat(index / 10) + 0.2;
        }
    };

    return (
        <div className="w-full">
            <div className="md:w-[700px] lg:w-[1200px] mx-auto pt-12 md:pt-20 lg:pt-28 px-6 lg:px-0">
                <h1 className="text-[27px] lg:text-[32px] font-fira font-semibold ">Need Something Done?</h1>
                <div className="pt-8 md:pt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                    {infosArray.map((info, i) => (
                        <VarientAnimation direction={i % 2 === 0 ? "down" : "up"} delay={indexPreparedForLawyer(i)}>
                            <div key={info.id} className="shadow-md p-4 rounded w-full md:w-[290px] h-[250px]">
                                <div className="flex items-center justify-start">
                                    <img className="w-20 h-20" src={info.icon} alt="" />
                                    <h3 className="text-[18px] font-Nunito font-semibold">{info.heading}</h3>
                                </div>
                                <p className="font-Nunito font-normal text-[14px] lg:text-[16px]">{info.description}</p>
                            </div>
                        </VarientAnimation>
                    ))}
                </div>
                <hr className="mt-12 md:mt-20 lg:mt-28 border w-[328px] md:w-full" />
            </div>
        </div>
    );
};

export default NeedSomethingDone;
