import { Inbox } from "../../../components/Chat/Messege/Inbox/Inbox";
import { MessegeBox } from "../../../components/Chat/Messege/Messeges/MessegeBox";
import { Conversations } from "../../../components/Chat/MobileMessege/Conversations/Conversations";
import { MobileInbox } from "../../../components/Chat/MobileMessege/MobileInbox";

const ChatPrivateRoutes = [
  {
    id: 1,
    path: "/inbox",
    element: <Inbox />,
    nestedRoutes: [
      {
        id: 1,
        path: ":id",
        element: <MessegeBox />,
      },
    ],
  },
  {
    id: 2,
    path: "/mobile-conversations",
    element: <Conversations />,
  },
  {
    id: 3,
    path: "/mobile-inbox/:id",
    element: <MobileInbox />,
  },
];

export default ChatPrivateRoutes;
