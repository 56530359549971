// configuration
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";

// third party package
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// components
import { useDispatch, useSelector } from "react-redux";
import isLoggedIn from "../../../../../Helper/hooks/checkLoggerPersestency/isLoggedIn";
import { loadUserData } from "../../../../../Redux/features/userInfo/userInfo";

// css import;
import "./TopMenu.css";
import removeCookie from "../../../../../Helper/cookies/removeCookie";
import customFunc from "../../../../../assets/utilities/customFunc";

export const TopMenu = () => {
  // hooks variables
  const user = isLoggedIn();
  const userInfo = useSelector(
    (state) => state?.persistedReducer?.userInfo?.userInfo?.user
  );

  let { profilePhoto } = userInfo || {};

  // profile photo decision maker
  const { profilePhotoDecisionMaker } = customFunc;
  profilePhoto = profilePhotoDecisionMaker(profilePhoto);

  let name;
  if (userInfo?.firstName) {
    name = userInfo?.firstName || "Empty";
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutButton = async () => {
    removeCookie("token");
    dispatch(loadUserData(null));
    navigate("/");
    localStorage.removeItem("accessToken");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // function
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div className="flex items-center justify-between bg-top rounded px-4 md:px-6 py-2">
        <div>
          {user ? (
            <div className="flex items-center gap-2">
              <img
                src={profilePhoto}
                className="rounded-full w-8"
                alt="Not Available"
              ></img>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="font-bold text-black"
              >
                <span className="text-black font-bold">
                  {" "}
                  {name}
                  <KeyboardArrowDownIcon fontSize="small" />
                </span>
              </Button>
            </div>
          ) : (
            <div className="flex items-center">
              <div className="mov-top w-8 rounded-full mt-1">
                <img
                  src="https://i.ibb.co/pZFRdCB/512-512-Icon-1.png"
                  alt="Not Available"
                ></img>
              </div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="font-bold text-black"
              >
                <span className="text-black font-bold">
                  {" "}
                  Shongshari.com
                  <KeyboardArrowDownIcon fontSize="small" />
                </span>
              </Button>
            </div>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              {user ? (
                <div>
                  <button
                    onClick={logoutButton}
                    className="bg-login-mov-home rounded-full text-white px-3"
                  >
                    LogOut
                  </button>
                </div>
              ) : (
                <div className="login-signup">
                  <Link to="/mobile-login">
                    <button className="bg-login-mov-home rounded-full text-white px-3">
                      Log In
                    </button>
                  </Link>
                  <p className="mx-2">Or</p>
                  <Link to="/mobile-signup">
                    <button className="bg-login-mov-home rounded-full text-white px-3">
                      Sign Up
                    </button>
                  </Link>
                </div>
              )}
            </MenuItem>
          </Menu>
        </div>
        <div className="col-span-1 mt-2">
          <Link to="/notifications">
            <NotificationsIcon fontSize="small" />
          </Link>
          <Link to="/setting">
            {" "}
            <MenuIcon fontSize="small" />
          </Link>
        </div>
      </div>
    </div>
  );
};
