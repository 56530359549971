import React from "react";
import { BottomNav } from "../BottomNav";
import { HistoryBannerMov } from "./HistoryBannerMov";

export const HistoryMov = () => {
  return (
    <div>
      <HistoryBannerMov></HistoryBannerMov>
      <div className="h-20"></div>
      <BottomNav></BottomNav>
    </div>
  );
};
