// Configuration
import React, { useEffect, useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";

// Components
import { useGetAllServicesByroleQuery } from "../../../Redux/features/Service/ServiceApi";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";
import ServicesPromoCard from "../Home/ServicesPromo/ServicesPromoCard/ServicesPromoCard";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import Footer from "../../shared/Footer/Footer";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import { BottomNav } from "../../../Wrapper/HomePage/mobileversion/BottomNav";

const AllServicesPage = () => {
  const [page, setPage] = useState(1);
  const loaderArr = [1, 2, 3, 4];
  const { data, isLoading, error } = useGetAllServicesByroleQuery({
    role: "agent",
    page: page,
    limit: 12,
  });

  const { total } = data || {};
  let totalPage = Math.ceil(parseInt(total) / 12) || 1;

  let content;
  if (isLoading) {
    content = (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-16">
        {loaderArr.map((arr) => (
          <div key={arr} className="animate-pulse">
            <div className="h-40 bg-slate-100"></div>
            <div className="h-4 mt-4 bg-slate-100"></div>
            <div className="h-4 mt-2 bg-slate-100"></div>
          </div>
        ))}
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex justify-center my-24">
        <ServerErrorMessage />
      </div>
    );
  } else if (!error && data?.services?.length === 0) {
    content = (
      <div className="my-24 w-full flex justify-center items-center text-xl font-Poppins text-gray-400">
        No Agent services found!
      </div>
    );
  } else if (!error && data?.services?.length > 0) {
    content = (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 my-8 md:my-14">
        {data?.services?.map((service) => {
          return <ServicesPromoCard key={service?._id} {...{ service }} />;
        })}
      </div>
    );
  }

  // Scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="hidden lg:block">
        <CustomHeader title={"All Services"} />
        <div className="max-w-[1200px] mx-auto">{content}</div>
        <div className="my-8 max-w-[1200px] mx-auto flex justify-center">
          <Pagination
            count={totalPage}
            variant="outlined"
            color="secondary"
            onChange={(event, value) => setPage(value)}
          />
        </div>
        <Footer />
      </div>

      {/* Mobile version */}
      <div className="lg:hidden">
        <MobileBackButton name={"All Services"} />
        <div className="px-6">{content}</div>
        <div className="my-4 px-6 flex justify-center">
          <Pagination
            count={totalPage}
            variant="outlined"
            color="secondary"
            onChange={(event, value) => setPage(value)}
          />
        </div>
        <div className="h-20"></div>
        <BottomNav />
      </div>
    </div>
  );
};

export default AllServicesPage;
