import React from "react";
import { useNavigate } from "react-router-dom";
import VarientAnimation from "../../../../../assets/utilities/Animation/VarientAnimation";

const ServicesPromoCard = ({ service }) => {
    const navigate = useNavigate();
    return (
        <VarientAnimation direction="up" delay={0.3}>
            <div className="w-full md:w-[290px] h-[200px] overflow-hidden">
                <div className="relative w-full md:w-[290px] h-[200px] duration-700 hover:scale-125">
                    <div className="absolute inset-0 h-full opacity-100 transition duration-500 ease-in-out">
                        <img className="w-full md:w-[290px] h-[200px] duration-700 hover:scale-110" src={service?.photos[0]} alt="" />
                    </div>
                    <div className="absolute inset-0 h-full bg-black opacity-0 transition duration-700 ease-in-out hover:opacity-60 flex justify-center items-center">
                        <button
                            onClick={() => navigate(`/singleService/${service?._id}`)}
                            className="px-5 py-2 border border-white text-white rounded-sm duration-500 bg-white bg-opacity-0 hover:bg-opacity-25"
                        >
                            View Details
                        </button>
                    </div>
                </div>
            </div>
            <div className="text-[18px] font-semibold font-Nunito mt-5">
                <h2>{service?.title}</h2>
                <p className="leading-6">{service?.price} BDT</p>
            </div>
        </VarientAnimation>
    );
};

export default ServicesPromoCard;
