import React, { Suspense } from "react";
import Banner from "../../components/pages/Home/Banner/Banner";
import CategoriesPromo from "../../components/pages/Home/CategoriesPromo/CategoriesPromo";
import KnowMore from "../../components/pages/Home/KnowMore/KnowMore";
import NeedSomethingDone from "../../components/pages/Home/NeedSomethingDone/NeedSomethingDone";
import PackagePromo from "../../components/pages/Home/PackagePromo/PackagePromo";
import ServicesPromo from "../../components/pages/Home/ServicesPromo/ServicesPromo";
import WhyChooseUs from "../../components/pages/Home/WhyChooseUs/WhyChooseUs";
import { BrandLoader } from "../../components/shared/Cards/Loader/BrandLoader/BrandLoader";
import Footer from "../../components/shared/Footer/Footer";
import NavBar from "../../components/shared/NavBar/NavBar";
import MobileHome from "./mobileversion/MobileHome/MobileHome";

const HomePage = () => {
    return (
        <div className="bg-[rgb(248,248,255)]">
            <Suspense fallback={<BrandLoader />}>
                <div className="hidden lg:block">
                    <NavBar />
                    <Banner />
                    <NeedSomethingDone />
                    <WhyChooseUs />
                    <ServicesPromo />
                    <CategoriesPromo />
                    <KnowMore />
                    <PackagePromo />
                    <Footer />
                </div>

                {/* Mobile version */}
                <div className="lg:hidden">
                    <MobileHome />
                </div>
            </Suspense>
        </div>
    );
};

export default HomePage;
